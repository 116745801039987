<template>
  <div class="current-news-component">
    <v-container>
      <v-row class="post">
        <v-col cols="12">
          <p class="post__title">{{ objCurrentNews.name }}</p>
        </v-col>
        <v-col cols="12">
          <div class="post__image">
            <img :src="'https://old.medveddoc.com' + objCurrentNews.image" alt="" />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="post__text">
            <p v-html="objCurrentNews.content"></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["url"],
  name: "CurrentNewsComponent",
  created() {
    this.getCurrentNews({ slug: this.url });
  },
  computed: {
    ...mapGetters({
      objCurrentNews: names.OBJ_CURRENT_NEWS,
    }),
  },
  methods: {
    ...mapActions({
      getCurrentNews: names.ACTION_GET_CURRENT_NEWS,
    }),
  },
};
</script>

<style scoped lang="scss">
.post {
  width: 100%;
  font-family: $sanspro;
  max-width: 930px;
  margin: 50px auto;

  &__image {
    max-width: 90%;
    margin: 0 auto;
    overflow-y: hidden;
    max-height: 300px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__title {
    line-height: 29px;
    margin-bottom: 20px;
  }
  &__date {
    font-size: 15px;
    margin: 20px 0;
  }

  &__text {
    max-width: 90%;
    padding-bottom: 150px;
    margin: 0 auto;
    text-align: justify;
  }

  @include phone-only {
    .post__image {
      max-width: 100%;
    }
    .post__text {
      max-width: 100%;
    }
  }
}
</style>
